@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Playfair Display", serif; logo-heading */
  font-family: "Merriweather", serif;
  text-decoration: none;
}

.conatiner {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 1rem;
}

.header {
  width: 100%;
  padding: 10px;
  background-color: rgb(230, 230, 230);
  margin-bottom: 1rem;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
}

.nav-logo p {
  font-family: "Playfair Display", serif;
  font-size: 34px;
  color: black;
  font-weight: 700;
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  margin-bottom: 0.8rem;
}

.banner img {
  width: 50%;
}
.banner .banner-text {
  width: 50%;
}

/* ----------------------form section---------------------- */

.form-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
}

.form-warper {
  background-color: #fff;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  position: relative;
}

.form-warper h2 {
  padding-bottom: 0.8rem;
}

.close-button {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 40px;
  cursor: pointer;
}

.form-btn {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

/* ----------------------------------buttons section----------------------------------- */
.app-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.heading {
  font-size: 4.5rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: fadeIn 2s ease-in-out;
}

.heading::after {
  content: "";
  width: 50px;
  height: 4px;
  background: #3498db;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  border-radius: 2px;
  animation: slideIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 50px;
    opacity: 1;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.card {
  width: 100%;
  max-width: 200px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card img {
  width: 100%;
  max-width: 90px;
  margin-bottom: 0.8rem;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.card img:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.btn-audio {
  padding: 10px 20px;
  border: none;
  background-color: rgb(196, 243, 255);
  font-size: 18px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-audio:hover {
  background-color: #3498db;
  color: white;
  transform: scale(1.1);
}

/* ---------------on click button css--------------- */

.card {
  width: 100%;
  max-width: 220px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card img {
  width: 100%;
  max-width: 90px;
  margin-bottom: 0.8rem;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.card img:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.btn-content button {
  padding: 10px;
  border: none;
  font-size: 18px;
  background-color: #3498db;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* ---------------------------loop btn css--------------------------- */
.loopAudioBtn {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.loopAudioBtn button {
  text-transform: capitalize;
  padding: 10px;
  background-color: #3498db;
  border-radius: 10px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
}

/* Responsive CSS */
@media (max-width: 1200px) {
  .container {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 992px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 768px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
  .banner {
    flex-wrap: wrap;
    justify-content: center;
  }
  .banner .banner-text,
  .banner img {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    max-width: 100%;
  }
  .nav-logo p {
    font-size: 28px;
  }
}
@media (max-width: 414px) {
  .nav-logo p {
    font-size: 20px;
  }
  .card img {
    max-width: 60px;
  }
  .btn-audio {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 360px) {
 .nav-logo p {
    font-size: 18px;
  }
}
